import { useCallback, useEffect, useMemo, useState } from "react";
import { isSupported } from "firebase/messaging";
import { APIRequestGenerator } from "base/BaseActions";
import APIHelper from "base/APIHelper";
import BaseModel from "base/BaseModel";
import { requestFcmToken } from "base/firebase";
import { errorResponseGenerator } from "base/ErrorHelper";

const module = "user";

const useUserHook = (savedModelInstance = {}) => {
  // External Hooks

  // States, Memos, Refs
  const [token, setToken] = useState(savedModelInstance?.token);
  const [userData, setUserData] = useState(savedModelInstance?.userData);
  const [fcmToken, setFcmToken] = useState(savedModelInstance?.fcmToken);

  const Merchant = useMemo(() => userData?.Merchant?._id, [userData]);
  const merchantData = useMemo(() => userData?.Merchant || {}, [userData]);

  const isSystem = useMemo(() => userData?.level === "system", [userData]);

  const isMonthlyEnable = useMemo(
    () => merchantData?.feature?.booking?.monthly,
    [merchantData]
  );

  const isHourlyEnable = useMemo(
    () => merchantData?.feature?.booking?.hourly,
    [merchantData]
  );

  // API Calls
  async function login(data) {
    const { url } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/login`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        {
          ...data,
          role: "Admin",
        },
        true,
        null
      );

      if (response?.data?.token) {
        setToken(response.data.token);
      }
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getMe(inputToken) {
    if (!inputToken && !token) {
      console.log("logout");
      logout();
      throw errorResponseGenerator("Please Login.");
    }

    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/me`,
      { _populate: "Merchant" },
      inputToken || token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      const { data } = response || {};
      const { AdminGroup = {} } = data || {};
      const { Permissions = [], level, isAllGranted } = AdminGroup || {};
      if (!AdminGroup) {
        throw errorResponseGenerator(
          "Fail to get Admin Group,Please try again"
        );
      }

      if (data) {
        setUserData({
          ...data,
          Permissions,
          level,
          isAllGranted,
        });
      }
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getList(params, type = "customer") {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${type}`,
      isSystem ? params : { Merchant, ...params },
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function updateOne(id, data, type = "customer") {
    const submitData = { ...data, Merchant };
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${type}/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        submitData,
        true,
        headers
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function createOne(data, type = "customer") {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${type}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { Merchant, ...data },
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getOne(id, params, type = "customer") {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${type}/${id}`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        true,
        headers
      );
      const { records } = response || {};
      const [record = {}] = records || [];
      return Promise.resolve(record);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function verifyPhone(phone) {
    try {
      const { data } = await getList({ "phone.number": phone });
      return Promise.resolve(data);
    } catch (error) {
      return Promise.resolve(error);
    }
  }

  async function forgotPassword(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/forgotPassword`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function resetPassword(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/resetPassword`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function importFile(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/import`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function exportToCSV(params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/action/export/csv`,
      { Merchant, ...params },
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        false,
        headers,
        true
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function resendActivationLink(id) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/resendActivationLink/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function resendEmailVerification(id) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/resendEmailVerification/${id}`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        null,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function handleCheckUserExist(data, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/user/checkUserExist`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { Merchant, ...data },
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function updateMe(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/user/me`,
      null,
      token
    );
    try {
      const response = await APIHelper(
        "PUT",
        url.toString(),
        data,
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function migrateCustomer(data, params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/user/customer/migrate`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { merchantId: Merchant, ...data },
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function sendOTP(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/otp/send`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { merchantId: Merchant, ...data },
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function otpLogin(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/login/otp`,
      null,
      null
    );
    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        data,
        true,
        headers
      );

      if (response?.data?.token) {
        setToken(response.data.token);
      }

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getValidBookings(id, params, type = "customer") {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/${module}/${type}/${id}/bookings`,
      params,
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        false,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function cleanFMCToken() {
    try {
      await updateMe({
        fcmToken,
      });
    } catch (error) {
      setFcmToken("");
    }
  }

  // Functions and Callbacks

  const logout = async (navigate) => {
    setToken(null);
    setFcmToken(null);

    if (fcmToken) {
      await updateMe({
        fcmToken: "",
      });
    }

    window.localStorage.removeItem("UserModel");
    if (navigate) {
      navigate("/");
    }

    // window.location.reload();
    setUserData({});
  };

  const requestPermission = async () => {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser && !fcmToken)
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            return requestFcmToken(setFcmToken);
          }
          return Notification.requestPermission();
        })
        .catch((err) => {
          console.log(err);
        });
  };

  // onSaveInstanceState / onRestoreInstanceState

  const onSaveInstanceState = useCallback(
    () => ({
      token,
      userData,
      fcmToken,
    }),
    [token, userData, fcmToken]
  );

  // useEffects

  useEffect(() => {
    if (fcmToken && token) {
      cleanFMCToken();
    }
  }, [fcmToken, token]);

  return {
    // State
    token,
    fcmToken,
    setFcmToken,
    login,

    // onSaveInstanceState / onRestoreInstanceState
    onSaveInstanceState,

    // Function
    logout,

    userData,
    Merchant,
    isSystem,
    merchantData,
    isMonthlyEnable,
    isHourlyEnable,

    // API Calls
    getMe,
    getOne,
    getList,
    updateOne,
    createOne,
    verifyPhone,
    resetPassword,
    importFile,
    exportToCSV,
    forgotPassword,
    resendActivationLink,
    resendEmailVerification,
    handleCheckUserExist,
    updateMe,
    migrateCustomer,
    requestPermission,
    sendOTP,
    otpLogin,
    getValidBookings,
  };
};

const UserModel = BaseModel(useUserHook, "UserModel");

export { useUserHook };
export default UserModel;
