import UserModel from "./userModel";
import AppModel from "./appModel";
import MerchantModel from "./merchantModel";
import PermissionModel from "./permissionModel";
import CarParksModel from "./carParksModel";
import ParkingSlotModel from "./parkingSlotModel";
import ServicePlanModel from "./servicePlanModel";
import PolicyPlanModel from "./policyPlanModel";
import ServiceAddOnModel from "./serviceAddOnModel";
import PriceSettingModel from "./priceSettingModel";
import PaymentMethodModel from "./paymentMethod";
import LeaseModel from "./leaseModel";
import BookingModel from "./bookingModel";
import TransactionModel from "./transactionModel";
import InvoiceModel from "./invoiceModel";
import VehicleTypeModel from "./vehicleTypeModel";
import UserGroupModel from "./userGroupModel";
import BookingRequestModel from "./bookingRequestModel";
import DistrictModel from "./districtModel";
import ServiceTagModel from "./serviceTagModel";
import PromotionModel from "./promotionModel";
import ImportBatchModel from "./importBatch";
import ReceiptModel from "./receiptModel";
import HolidayModel from "./holidayModel";
import VehicleModel from "./vehicleModel";
import NotificationModel from "./notificationModel";
import SiteConfigModel from "./siteConfigModel";
import ServiceModel from "./serviceModel";
import NewsModel from "./newsModel";
import FAQsModel from "./faqsModel";
import AwardModel from "./awardModel";
import DatePriceModel from "./datePriceModel";
import CollectionMethodModel from "./collectionMethodModel";
import OCRModel from "./ocrModel";
import ParkingAreaModel from "./parkingAreaModel";
import ParkingRateModel from "./parkingRateModel";
import VehicleGroupModel from "./vehicleGroupModel";
import ParkingTransactionModel from "./parkingTransaction";
import PriceSettingGroupModel from "./priceSettingGroupModel";
import ExternalAppModel from "./externalAppTokenModel";
import NotificationTemplateModel from "./notificationTemplateModel";
import ErrorRecordModel from "./errorRecordModel";
import MigrationConnectionModel from "./migrationConnectionModel";
import MigrationConfigModel from "./migrationConfigModel";
import EnquiryModel from "./enquiryModel";
import CameraConnectionModel from "./cameraConnectionModel";
import CameraModel from "./cameraModel";
import GateActionLogRemarkModel from "./gateActionLogRemark";
import GateActionModel from "./gateActionModel";
import OperationalLogModel from "./operationalLogModel";
import PaymentTransactionModel from "./paymentTransaction";
import PricingSchemeModel from "./pricingSchemeModel";
import PromotionGroupModel from "./promotionGroupModel";
import SpecializedLPNsModelModel from "./specializedLPNsModel";
import PopUpModel from "./popUpModel";
import EvContractorModel from "./evContractor";
import EvChargerModel from "./evCharger";
import EvConnectorModel from "./evConnector";
import TransmissionModel from "./transmissionModel";
import ACSModel from "./acsModel";

const Models = [
  // Add new Models here
  ACSModel,
  TransmissionModel,
  EvConnectorModel,
  EvChargerModel,
  EvContractorModel,
  MigrationConfigModel,
  MigrationConnectionModel,
  SpecializedLPNsModelModel,
  PromotionGroupModel,
  PricingSchemeModel,
  PaymentTransactionModel,
  OperationalLogModel,
  GateActionModel,
  GateActionLogRemarkModel,
  CameraModel,
  CameraConnectionModel,
  EnquiryModel,
  ErrorRecordModel,
  ExternalAppModel,
  PriceSettingGroupModel,
  ParkingRateModel,
  ParkingAreaModel,
  AppModel,
  MerchantModel,
  PermissionModel,
  CarParksModel,
  ParkingSlotModel,
  ServicePlanModel,
  PolicyPlanModel,
  ServiceAddOnModel,
  PriceSettingModel,
  PaymentMethodModel,
  LeaseModel,
  BookingModel,
  TransactionModel,
  InvoiceModel,
  VehicleTypeModel,
  UserGroupModel,
  BookingRequestModel,
  DistrictModel,
  ServiceTagModel,
  PromotionModel,
  ImportBatchModel,
  ReceiptModel,
  HolidayModel,
  VehicleModel,
  NotificationModel,
  SiteConfigModel,
  ServiceModel,
  NewsModel,
  FAQsModel,
  AwardModel,
  DatePriceModel,
  CollectionMethodModel,
  OCRModel,
  VehicleGroupModel,
  ParkingTransactionModel,
  NotificationTemplateModel,
  UserModel,
  PopUpModel,
  // Don't add new Model here
];

const BindModels = (ViewComponent) => {
  let bindedComponent = ViewComponent;
  Models.forEach((model) => {
    bindedComponent = model.withProvider(bindedComponent);
  });
  return bindedComponent;
};

export { BindModels };
export default Models;
