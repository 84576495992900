import { useContext } from "react";
import { APIRequestGenerator } from "base/BaseActions";
import BaseModel from "base/BaseModel";
import APIHelper from "base/APIHelper";
import UserModel from "./userModel";

const useACSModel = (savedModelInstance = {}) => {
  const { token, Merchant } = useContext(UserModel.Context);

  async function getContractorCustomer(params) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/ContractorCustomer`,
      { type: "ACSLegacyCustomer", ...params },
      token
    );
    try {
      const response = await APIHelper(
        "GET",
        url.toString(),
        null,
        false,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function syncCustomerInfo(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/carpark/contractor/ACSLegacy`,
      null,
      token
    );

    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { merchantId: Merchant, action: "ResyncCustomer", ...data },
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function linkLeaseWithPasscard(data) {
    const { url, headers } = APIRequestGenerator(
      `${process.env.REACT_APP_HOST}/carpark/contractor/ACSLegacy`,
      null,
      token
    );

    try {
      const response = await APIHelper(
        "POST",
        url.toString(),
        { merchantId: Merchant, action: "LinkCustomerToLease", ...data },
        true,
        headers
      );

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return {
    getContractorCustomer,
    syncCustomerInfo,
    linkLeaseWithPasscard,
  };
};

const ACSModel = BaseModel(useACSModel, "ACSModel");

export { useACSModel };
export default ACSModel;
